import Head from 'next/head';

interface Props {
  title: string;
}

const description = `Coulisse Gallery focuses on presenting young, cutting-edge artists and designers, from Scandinavia and beyond. With a collaborative approach at its core, the gallery aims to bring a vibrant new energy to the Stockholm art scene, connecting artists and creatives from different disciplines who have common visual and conceptual threads in their practices.`;

const Header = ({ title }: Props) => {
  return (
    <Head>
      <title>{title}</title>

      <meta name="name" content={title} />
      <meta name="description" content={description} />

      <link rel="icon" href="/assets/favicon/favicon.ico" />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/assets/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/assets/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/assets/favicon/favicon-16x16.png"
      />
      <link rel="manifest" href="/assets/favicon/site.webmanifest" />
      <meta name="msapplication-TileColor" content="#da532c" />
    </Head>
  );
};

export default Header;
