import style from './Menu.module.scss';
import { ApolloProvider } from '@apollo/client';
import client from '../../utils/apollo-client';
import { useRouter } from 'next/router';
import ActiveLink from '../layout/ActiveLink';
import Button from '../layout/Button';
import { useEffect, useState } from 'react';

const links = [
  {
    text: 'Exhibitions',
    url: '/',
    match: /\/^[A-Z]/gi,
    children: [],
  },
  {
    text: 'Artists',
    url: '/artists',
    match: /\/artist/gi,
    children: [],
  },
  { text: 'Info', url: '/info', match: /\/info/gi },
];

const Menu = () => {
  const router = useRouter();
  const { asPath } = router;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);

    const closeMenu = () => {
      setIsOpen(false);
    };

    router.events.on('routeChangeComplete', closeMenu);

    return () => {
      router.events.off('routeChangeComplete', closeMenu);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  return (
    <>
      <div
        style={{
          top: 0,
          padding: `10px 0 `,
          right: 10,
          height: 50,
          display: 'flex',
          alignContent: 'center',
          position: 'fixed',
          zIndex: 1002,
        }}
      >
        <Button
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          text={isOpen ? 'Close' : 'Menu'}
          isActive={isOpen}
        />
      </div>
      <div
        onClick={() => {
          setIsOpen(false);
        }}
        className={style.overlayBackground}
        style={{
          pointerEvents: isOpen ? 'all' : 'none',
          visibility: isOpen ? 'visible' : 'hidden',
        }}
      />
      <div
        className={`${style.menu}`}
        style={{ transform: !isOpen ? `translate(100%)` : '' }}
      >
        <div className={style.header}>
          {/* <h3>Menu</h3> */}
          <h3>Coulisse Gallery</h3>
        </div>
        <div className={style.body}>
          <ul>
            {links.map((item, i) => (
              // <li key={i} className={item.url === asPath ? style.active : ''}>
              <li
                key={i}
                className={
                  asPath.match(item.match) ||
                  (item.url === '/' && asPath === '/')
                    ? style.active
                    : ''
                }
              >
                <ActiveLink href={item.url} activeClassName={style.active}>
                  <div className={style.menuMainLink}>{item.text}</div>
                </ActiveLink>
              </li>
            ))}
          </ul>
          {/* <MenuTheme /> */}
        </div>
      </div>
    </>
  );
};

const WrappedMenu = (props) => {
  return (
    // @ts-ignore // Nescessary with apollo-boost instead of @apollo/client
    <ApolloProvider client={client}>
      <Menu {...props} />
    </ApolloProvider>
  );
};

export default WrappedMenu;
