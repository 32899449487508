import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';

interface IContext {
  currentProductSlug: any;
  // setCurrentProductSlug: (value: any) => void;
  setCurrentProductSlug: any;
}

export const ContextShopQueries = React.createContext<IContext | null>(null);

export const ContextShopQueriesProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter();

  // Currently displayed Product
  const [currentProductSlug, setCurrentProductSlug] = useState(null);

  const value = useMemo(
    () => ({
      currentProductSlug,
      setCurrentProductSlug,
    }),
    [currentProductSlug]
  );

  useEffect(() => {
    // Register update inside of a product slug???
    let newUrl = '';

    // Make sure queries are kept!
    const queries =
      window.history.state.as.match(/\?/gi) &&
      window.history.state.as.split('?').pop();

    // Only proceed it currently on a Shop or Article Page!
    if (!window.history.state.as.match('/shop')) return;

    if (currentProductSlug) {
      newUrl = `/shop/${currentProductSlug}`;
    } else {
      newUrl = `/shop${queries ? `?${queries}` : ''}`;
    }

    window.history.pushState(
      { ...window.history.state, as: newUrl, url: newUrl },
      '',
      newUrl
    );
  }, [currentProductSlug]);

  useEffect(() => {
    if (router.asPath.match('/shop')) {
      const slug = router.asPath.split('/').pop();

      if (slug !== 'shop' && !slug.match(/\?/gi)) setCurrentProductSlug(slug);
    }
  }, []);

  return (
    <ContextShopQueries.Provider value={value}>
      {children}
    </ContextShopQueries.Provider>
  );
};
