import style from './Button.module.scss';

interface Props {
  // @ts-ignore
  // onClick: (e: any) => any;
  onClick: any;
  text: string;
  isActive?: boolean;
}

const Button = ({ onClick, text, isActive }: Props) => {
  return (
    <button
      aria-label={text}
      onClick={onClick}
      className={`${style.button} ${isActive ? style.isActive : ''}`}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

export default Button;
