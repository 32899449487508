// import ApolloClient, { InMemoryCache } from 'apollo-boost';
// import { ApolloClient, InMemoryCache } from '@apollo/client';

// const client = new ApolloClient({
//   cache: new InMemoryCache(),
//   uri: 'https://gapi.storyblok.com/v1/api?token=FRycspWWRBQjcjOJILcCawtt',
//   // uri: process.env.STORYBLOK_GRAPHQL_API,
//   // request: (operation) => {
//   //   operation.setContext({
//   //     headers: {
//   //       token: 'FRycspWWRBQjcjOJILcCawtt',
//   //       // token: process.env.STORYBLOK_ACCESS_TOKEN,
//   //       version: 'draft',
//   //     },
//   //   });
//   // },
// });

// export default client;

// import { createHttpLink } from '@apollo/client';
// import ApolloClient, { InMemoryCache } from 'apollo-boost';
import { ApolloClient, InMemoryCache } from '@apollo/client';
// import { config } from 'dotenv';
// require('dotenv');
// import dotenv from 'dotenv';
// import dotenv from 'dotenv';
// dotenv.config();

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: 'https://gapi.storyblok.com/v1/api',
  // uri: process.env.STORYBLOK_GRAPHQL_API,
  // link: createHttpLink({
  //   uri: () => getConfig().publicRunTimeConfig.STORYBLOK_GRAPHQL_API,
  //   // credentials: 'same-origin',
  //   // headers: httpLinkHeaders,
  // }),
  headers: {
    token: 'FeSw2GtKNjSivxAGLAH1rQtt',
    // token: 'FRycspWWRBQjcjOJILcCawtt',
    version: 'draft',
  },
  // link: authLink.concat(httpLink),
  // uri: 'https://gapi.storyblok.com/v1/api',
  // uri: process.env.STORYBLOK_GRAPHQL_API,
  // request: (operation) => {
  //   operation.setContext({
  //     headers: {
  //       token: 'FRycspWWRBQjcjOJILcCawtt',
  //       version: 'draft',
  //     },
  //   });
  // },
});

export default client;
