import { withRouter } from 'next/router';
import Link from 'next/link';
import React, { Children } from 'react';
import { ReactElementLike } from 'prop-types';

interface Props {
  router: any;
  children: ReactElementLike;
  href: string;
  as?: string;
  activeClassName: string;
  // ...props
}

const ActiveLink = ({ router, children, href, as, activeClassName }: Props) => {
  const child = Children.only(children);

  let className = child.props.className || '';
  if (router.pathname === href && activeClassName) {
    className = `${className} ${activeClassName}`.trim();
  }

  return (
    <Link href={href} as={as} passHref={true}>
      {React.cloneElement(child, { className })}
    </Link>
  );
};

export default withRouter(ActiveLink);
