import '../styles/style.scss';
import style from '../components/layout/Layout.module.scss';
import React, { useEffect, useState } from 'react';
import Header from '../components/layout/Header';
import Menu from '../components/menu/Menu';
import { useRouter } from 'next/router';
// import { AnimatePresence } from 'framer-motion';
import { ContextShopQueriesProvider } from '../components/context/ContextShopQueries';
// import GridTransitions from '../components/transition/GridTransitions';
import Intro from '../components/layout/Intro';

interface Props {
  Component: React.ReactNode;
  pageProps: any;
}

const App = ({
  Component,
  pageProps,
}: // title
Props) => {
  const title = 'Coulisse Gallery'; // PROVISORISCH?

  useEffect(() => {
    // FIX TRANSITIONS!!!
    Array.from(
      document.querySelectorAll('head > link[rel="stylesheet"][data-n-p]')
    ).forEach((node) => {
      node.removeAttribute('data-n-p');
    });
    const mutationHandler = (mutations) => {
      mutations.forEach(({ target }) => {
        if (target.nodeName === 'STYLE') {
          if (target.getAttribute('media') === 'x') {
            target.removeAttribute('media');
          }
        }
      });
    };
    const observer = new MutationObserver(mutationHandler);
    observer.observe(document.head, {
      subtree: true,
      attributeFilter: ['media'],
    });
    return () => {
      observer.disconnect();
    };
  }, []);

  const [vh, setVh] = useState(0);

  useEffect(() => {
    // --vh-offset

    setVh(window.innerHeight);

    const handleScroll = () => {
      setVh(window.innerHeight);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [state, setState] = useState<'visible' | 'faded' | 'removed'>(
    'visible'
  );

  return (
    <>
      <Intro state={state} setState={setState} />
      {state === 'faded' ||
        (state === 'removed' && (
          <ContextShopQueriesProvider>
            <Header title={title} />
            <Content pageProps={pageProps} Component={Component} />
          </ContextShopQueriesProvider>
        ))}
      <style>{`
      
        :root{
          --vh: ${vh}px;
        }
      `}</style>
    </>
  );
};

export default App;

const Content = ({ Component, pageProps }: Props) => {
  const router = useRouter();

  const showMenu = !router.pathname.match('worklist');

  return (
    <main className={`${style.main}`}>
      <div style={{ position: 'relative' }}>
        <div className="background" />
        {/* @ts-ignore */}
        <Component {...pageProps} key={router.asPath} />
      </div>
      {showMenu && <Menu />}
    </main>
  );
};
