import { CSSProperties, useEffect, useState } from 'react';
import style from './Intro.module.scss';

// const [state, setState] = useState<'visible' | 'faded' | 'removed'>(
//   'visible'
// );

interface Props {
  state: any;
  setState: any;
}

const Intro = ({ state, setState }: Props) => {
  // @ts-ignore

  const [fontPos, setFontPos] = useState<
    'start' | 'incoming' | 'waiting' | 'outgoing'
  >('start');

  useEffect(() => {
    const fontIncoming = setTimeout(() => {
      setFontPos('incoming');
    }, 100);
    const fontOutgoing = setTimeout(() => {
      setFontPos('outgoing');
    }, 2400);

    const fade = setTimeout(() => {
      setState('faded');
    }, 2600);

    const removed = setTimeout(() => {
      setState('removed');
    }, 3000);

    return () => {
      clearTimeout(fade);
      clearTimeout(removed);

      clearTimeout(fontIncoming);
      clearTimeout(fontOutgoing);
    };
  }, []);

  if (state === 'removed') return <></>;

  const styleWord1: CSSProperties = {};
  const styleWord2: CSSProperties = {};

  if (fontPos === 'start') {
    styleWord1.transform = `translateX(-30vw)`;
    styleWord2.transform = `translateX(30vw)`;
    styleWord1.opacity = `0`;
    styleWord2.opacity = `0`;
  } else if (fontPos === 'incoming') {
    styleWord1.transform = ``;
    styleWord2.transform = ``;
  } else if (fontPos === 'outgoing') {
    styleWord1.transform = `translateY(-50vh)`;
    styleWord2.transform = `translateY(50vh)`;
    styleWord1.opacity = `0`;
    styleWord2.opacity = `0`;
  }
  // styleWord1.transform = `translateX(-100vw)`;
  // styleWord2.transform = `translateX(100vw)`;

  return (
    <div
      className={style.intro}
      style={state !== 'visible' ? { opacity: '0' } : {}}
    >
      <h1>
        <div style={styleWord1}>Coulisse</div>
        <div style={styleWord2}>Gallery</div>
      </h1>
    </div>
  );
};

export default Intro;
